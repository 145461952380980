<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container flex-1 flex-v">

                <div class="flex-1 scrollybg secbox">
                    <div v-for="(items, index) in warnList">
                        <div class="row">
                            <div class="flex-1">
                                <el-row :gutter="15" class="warn">
                                    <el-col :span="4">
                                        <h6 class="tit">设备名称</h6>
                                        <div class="info">
                                            <el-select v-model="items.deviceName" placeholder="选择设备"
                                                @change="changeEquip(index)">
                                                <el-option v-for="item in equiments" :key="item.value" :label="item.name"
                                                    :value="item.name" />
                                                <el-option label="未选择" value="-1"></el-option>
                                            </el-select>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <h6 class="tit">表计名称</h6>
                                        <div class="info">
                                            <el-select v-model="items.gaugesName" placeholder="选择表计"
                                                @visible-change="getMeters($event, index)" @change="changeMeter(index)"
                                                :popper-class="meterSelectName(index)">
                                                <el-option v-for="item in meters" :key="item.value" :label="item.name"
                                                    :value="item.name" />
                                                <el-option label="未选择" value="-1"></el-option>
                                            </el-select>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <h6 class="tit">点位名称</h6>
                                        <div class="info">
                                            <el-select v-model="items.pointName" placeholder="选择点位"
                                                @visible-change="getPoints($event, index)" @change="change(index)"
                                                :popper-class="pointSelectName(index)">
                                                <el-option v-for="item in points" :key="item.value" :label="item.name"
                                                    :value="item.name" />
                                                <el-option label="未选择" value="-1"></el-option>
                                            </el-select>
                                        </div>
                                    </el-col>
                                    <el-col :span="2">
                                        <h6 class="tit">操作符</h6>
                                        <div class="info">
                                            <el-select v-model="items.operator" placeholder="选择操作符" @change="change(index)">
                                                <el-option label="==" value="=="></el-option>
                                                <el-option label="!=" value="!="></el-option>
                                                <el-option label="<" value="<"></el-option>
                                                <el-option label="<=" value="<="></el-option>
                                                <el-option label=">" value=">"></el-option>
                                                <el-option label=">=" value=">="></el-option>
                                                <el-option label="Contain" value="Contain"></el-option>
                                            </el-select>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <h6 class="tit">值</h6>
                                        <div class="info">
                                            <el-input v-model="items.threshold" placeholder="按类型填入数字或字符"
                                                @input="change(index)"></el-input>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <h6 class="tit">告警频率</h6>
                                        <div class="info flex">
                                            <div style="width: 45%; margin-right: 10px">
                                                <el-select v-model="items.notifyType" placeholder=""
                                                    @change="change(index)">
                                                    <el-option label="重复次数" value="1"></el-option>
                                                    <el-option label="重复时间（分）" value="2"></el-option>
                                                </el-select>
                                            </div>
                                            <div class="flex-1">
                                                <el-input v-model="items.notifyValue" placeholder="默认为0，重复告警"
                                                    @input="change(index)"></el-input>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="2">
                                        <h6 class="tit">告警级别</h6>
                                        <div class="info">
                                            <el-select v-model="items.notifyLevel" placeholder="" @change="change(index)">
                                                <el-option label="Warning" value="Warning"></el-option>
                                                <el-option label="Minor" value="Minor"></el-option>
                                                <el-option label="Major" value="Major"></el-option>
                                                <el-option label="Critical" value="Critical"></el-option>
                                            </el-select>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="15">
                                    <el-col :span="4" class="message">
                                        <h6 class="tit"></h6>
                                        <el-checkbox label="短信" name="type" v-model="items.smsFlag"
                                            @change="change(index)"></el-checkbox>
                                        <el-checkbox label="email" name="type" v-model="items.emailFlag"
                                            @change="change(index)"></el-checkbox>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="info flex">
                                            <span class="warninfo">描述</span>
                                            <div class="flex-1">
                                                <el-input v-model="items.remark" placeholder="所有设备名称+点位名称+点位描述+值"
                                                    @input="change(index)"></el-input>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div class="info flex">
                                            <span class="delete" @click="onDelete(index)"></span>
                                            <el-button class="button noconfirm" :class="changeClass(index)"
                                                v-bind:disabled="items.isDisabled" @click="onSubmit(index)">保存</el-button>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                        <div class="row-line"></div>
                    </div>

                    <div class="add">
                        <el-button class="button" @click="onAdd">添加告警</el-button>
                    </div>
                </div>
                <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                    layout="prev, pager, next, jumper,total" :total="pageInfo.total" :page-size="pageInfo.pageSize">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { warnSetList, addWarn, editWarn, delWarn } from "@/api/warn";
import { getEquipList } from "@/utils/common";
import { meterList} from "@/api/meter";
import { pointList } from "@/api/point";
export default {
    data() {
        return {
            editId: '0',
            equiments: [],
            meters: [],
            points: [],
            type4: [],
            warnList: [],
            pageInfo: {
                total: 0,
                pageSize: 4,
                pageNum: 1,
            },
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo
    },
    created() {

    },
    mounted() {
        this.getWarnSetList()
        this.equiments = getEquipList();
    },
    watch: {},
    methods: {
        pointSelectName(index) {
            return "pointSelect" + String(index)
        },
        meterSelectName(index) {
            return "meterSelect" + String(index)
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
            this.getWarnSetList();
        },
        //选择计量表
        getMeters(callback, index) {
            if (callback) {
                this.meters=[]
                var loadName = '.' + this.meterSelectName(index)
                const loading = this.$loading({
                    lock: true,
                    target: loadName,
                    spinner: "el-icon-loading",
                    background: "white"
                })
                let param = {
                    pageNum: 1,
                    pageSize: 999999999,
                    tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,  
                    deviceId:this.warnList[index].deviceId
                };
                meterList(param).then((res) => {
                    if (res.data.code == 0) {
                        for(let i=0;i<res.data.rows.length;i++){
                            let t={};
                            t.name=res.data.rows[i].name;
                            t.id=res.data.rows[i].id;
                            this.meters.push(t)
                            loading.close()
                        }
                    } else {
                        this.$message.warning(res.data.msg);
                    }
                });
            }
            
        },
        //选择数据点
        getPoints(callback, index) {
            if (callback) {
                this.points = []
                var loadName = '.' + this.pointSelectName(index)
                const loading = this.$loading({
                    lock: true,
                    target: loadName,
                    spinner: "el-icon-loading",
                    background: "white"
                })
                let param = {
                    pageNum: 1,
                    pageSize: 999999999,
                    gaugesId: this.warnList[index].gaugesId,
                    deviceId: this.warnList[index].deviceId,
                };
                pointList(param).then((res) => {
                    if (res.data.code == 0) {
                        for (let i = 0; i < res.data.rows.length; i++) {
                            let t = {};
                            t.name = res.data.rows[i].name;
                            t.id = res.data.rows[i].id;
                            this.points.push(t)
                            loading.close()
                        }
                    } else {
                        this.$message.warning(res.data.msg);
                    }
                });
            }

        },

        //变更计量表
        changeMeter(index) {
            this.warnList[index].pointId = ''
            this.warnList[index].pointName = ''
            this.warnList[index].isDisabled = false
        },
        //变更设备
        changeEquip(index) {
            for (let i = 0; i < this.equiments.length; i++) {
                if (this.equiments[i].name == this.warnList[index].deviceName) {
                    this.warnList[index].deviceId = this.equiments[i].id;
                }
            }
            this.warnList[index].gaugesId = ''
            this.warnList[index].gaugesName = ''
            this.warnList[index].pointId = ''
            this.warnList[index].pointName = ''
            this.warnList[index].isDisabled = false
        },
        //告警信息发生变更
        change(index) {
            this.warnList[index].isDisabled = false
        },

        //动态更改按钮样式
        changeClass(index) {
            return { confirm: !this.warnList[index].isDisabled }
        },
        //获取告警配置列表
        getWarnSetList() {
            let param = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                projectId: Number(sessionStorage.getItem("projectId")),
            };

            warnSetList(param).then((res) => {
                if (res.data.code == 0) {
                    this.pageInfo.total = res.data.total;
                    console.log(this.pageInfo)
                    this.warnList = res.data.rows;
                    for (let i = 0; i < this.warnList.length; i++) {
                        if (this.warnList[i].smsFlag == '1') {
                            this.warnList[i].smsFlag = true
                        }
                        else {
                            this.warnList[i].smsFlag = false
                        }
                        if (this.warnList[i].emailFlag == '1') {
                            this.warnList[i].emailFlag = true
                        }
                        else {
                            this.warnList[i].emailFlag = false
                        }
                        this.warnList[i].isDisabled = true
                    }
                } else {
                    this.$message.warning(res.data.msg);
                }
            });
        },
        //添加告警
        onAdd() {
            let t = {
                deviceId: '',
                deviceName: "",
                gaugesId: '',
                gaugesName: "",
                pointId: '',
                pointName: "",
                operator: "",
                threshold: "",
                notifyValue: '0',
                notifyType: "1",
                notifyLevel: "Warning",
                smsFlag: '',
                emailFlag: '',
                remark: '',
                isDisabled: false,
                addFlag: '1'
            }
            this.warnList.push(t)
        },
        //提交更改
        onSubmit(index) {
            for (let i = 0; i < this.equiments.length; i++) {
                if (this.equiments[i].name == this.warnList[index].deviceName) {
                    this.warnList[index].deviceId = this.equiments[i].id;
                }
            }
            for (let i = 0; i < this.meters.length; i++) {
                if (this.meters[i].name == this.warnList[index].gaugesName) {
                    this.warnList[index].gaugesId = this.meters[i].id;
                }
            }
            for (let i = 0; i < this.points.length; i++) {
                if (this.points[i].name == this.warnList[index].pointName) {
                    this.warnList[index].pointId = this.points[i].id;
                }
            }
            let param = {
                deviceId: this.warnList[index].deviceId,
                deviceName: this.warnList[index].deviceName,
                emailFlag: Number(this.warnList[index].emailFlag).toString(),
                gaugesId: this.warnList[index].gaugesId,
                gaugesName: this.warnList[index].gaugesName,
                notifyLevel: this.warnList[index].notifyLevel,
                notifyType: this.warnList[index].notifyType,
                notifyValue: this.warnList[index].notifyValue,
                operator: this.warnList[index].operator,
                pointId: this.warnList[index].pointId,
                pointName: this.warnList[index].pointName,
                projectId: Number(sessionStorage.getItem("projectId")),
                remark: this.warnList[index].remark,
                smsFlag: Number(this.warnList[index].smsFlag).toString(),
                tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
                tenantName: JSON.parse(sessionStorage.getItem("user")).tenantName,
                threshold: this.warnList[index].threshold
            };
            if ('addFlag' in this.warnList[index]) {
                addWarn(param).then((res) => {
                    if (res.data.code != 0) {
                        this.$message.warning(res.data.msg);
                    } else {
                        this.$message({
                            type: "success",
                            message: "新增告警配置成功!",
                        });
                        delete this.warnList[index]['addFlag']
                    }
                });
            } else {
                param.id = this.warnList[index].id;
                editWarn(param).then((res) => {
                    if (res.data.code != 0) {
                        this.$message.warning(res.data.msg);
                    }
                    else {
                        //此处不知道为什么就是改变不了按钮的disable，出此下策后更改数据后才可以
                        this.warnList.push({})
                        this.warnList.splice(this.warnList.length - 1, 1)


                        this.$message({
                            type: "success",
                            message: "修改告警配置成功!",
                        });
                    }
                });
            }
            this.$nextTick(() => {
                this.warnList[index].isDisabled = true;
                this.changeClass(index)
            });
        },

        //删除告警配置
        onDelete(index) {
            this.$confirm("此操作将永久删除该告警配置, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let param = {
                        ids: String(this.warnList[index].id),
                    };
                    delWarn(param).then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.warnList.splice(index, 1);
                        } else {
                            this.$message.warning(res.data.msg);
                            this.$message({
                                type: "info",
                                message: "已取消操作",
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        }
    }
};
</script>

<style scoped>
.container {
    border: 1px solid #4a4f64;
    border-radius: 3px;
    overflow: hidden;
    background-color: #1a1d31;
}

.top-tit {
    font-size: 16px;
    color: #ccd9e5;
    background-color: #3d3f55;
    line-height: 40px;
    padding: 0 35px;
}

.bt-box {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bt-box .bt {
    font-size: 16px;
    color: #ccd9e5;
    padding-left: 10px;
    position: relative;
}

.bt-box .bt span {
    position: relative;
}

.bt-box .bt::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    background: linear-gradient(to right,
            rgba(148, 67, 222, 1),
            rgba(148, 67, 222, 0.5));
    left: 0;
    bottom: 0;
}

.bt-box .edit {
    width: 70px;
    height: 26px;
    border-radius: 3px;
    background-color: #9443de;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.bt-box .edit .ico {
    margin-right: 5px;
}

.bt-box .edit:hover {
    opacity: 0.85;
}

.secbox {
    padding: 10px 30px;
}

.secbox .row {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.secbox .tit {
    font-size: 14px;
    color: #ccd9e5;
    line-height: 26px;
    margin-bottom: 5px;
}

.secbox .delete {
    width: 22px;
    height: 22px;
    background: url(../assets/images/icon_08.png) no-repeat center;
    background-size: contain;
    margin-left: 15px;
    display: block;
    cursor: pointer;
    margin-top: 10px;
}

.secbox .type {
    font-size: 14px;
    color: #ccd9e5;
    background-color: #42445c;
    width: 100%;
    text-align: center;
    line-height: 38px;
    display: block;
    border-radius: 4px;
    height: 40px;
    border: 1px solid #4a4f64;
}

.secbox .row-line {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.secbox .row-line::before,
.secbox .row-line::after {
    content: "";
    flex: 1;
    height: 1px;
    border-top: 1px dashed #5f3893;
}

.secbox .row-line .select {
    width: 100px;
    margin: 0 30px;
}

.secbox .row-line>>>.el-select .el-input__inner {
    background-color: #603994 !important;
    border-color: #603994 !important;
}

.secbox .addbutton {
    font-size: 14px;
    color: #ccd9e5;
    width: 100px;
    height: 40px;
    border: 1px dashed #4a4f64;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
}

.secbox .addbutton:hover {
    background-color: #5f3893;
    border-color: #5f3893;
}

.ms-sec {
    padding: 15px 20px;
    background-color: #393c51;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ms-sec .lbox {
    display: flex;
    align-items: center;
    max-width: 540px;
    width: 40%;
}

.ms-sec .wz {
    font-size: 14px;
    color: #ccd9e5;
}

.ms-sec .rbox {
    min-width: 30%;
}

.ms-sec .row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.ms-sec .row:last-child {
    margin-bottom: 0;
}

.ms-sec .row .tit {
    font-size: 14px;
    color: #ccd9e5;
    min-width: 170px;
}

.ms-sec .row .dot {
    width: 34px;
    height: 16px;
    border-radius: 3px;
    margin-right: 13px;
    display: inline-block;
    vertical-align: middle;
}

.warn {
    margin-bottom: 20px;
}

.message {
    height: 40px;
}

.warninfo {
    font-size: 14px;
    color: #ccd9e5;
    line-height: 26px;
    margin-top: 5px;
    margin-right: 10px;
}

.button {
    border-radius: 3px;
    background-color: #9443de;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.confirm {
    background-color: #9443de !important;
}

.confirm .ico {
    margin-right: 5px;
}

.confirm:hover {
    opacity: 0.85;
}


.noconfirm {
    width: 70px;
    height: 35px;
    border-radius: 3px;
    background-color: #28d1c9;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    margin-left: 20px;
}

.add {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
